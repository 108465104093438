// COMPONENTS

// STYLE
import "./sidebarAvailability.css";
import "../../../styles/layout.scss";

// THIS IS A GLOBAl IMPORT: DON'T TOUCH IT!!
/* eslint-disable no-unused-vars */
import i18n from "i18next";
import PropTypes from "prop-types";

const SidebarAvailability = ({ isBusy }) => {
  return (
    <div
      className={`col-1 h-100 sidebar-availability ${
        isBusy === true ? "bg-color-sidebar-busy" : "bg-color-sidebar-free"
      }`}
    >
      {isBusy === true ? (
        <p className="rotated-lbl center-white-busy-room font-color-sidebar-busy font-family-secondary">Sala occupata</p>
      ) : (
        <p className="rotated-lbl center-white-busy-room font-color-sidebar-free font-family-secondary">Sala libera</p>
      )}
    </div>
  );
};

SidebarAvailability.propTypes = {
  isBusy: PropTypes.bool.isRequired,
};

export default SidebarAvailability;

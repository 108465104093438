// COMPONENTS

// STYLE
import "./nextMeeting.css";
import "../SidebarAvailability/sidebarAvailability.css";

// THIS IS A GLOBAl IMPORT: DON'T TOUCH IT!!
/* eslint-disable no-unused-vars */
import i18n from "i18next";
import PropTypes from "prop-types";
import { useEffect, useRef, useState } from "react";
import * as dayjs from "dayjs";
import IntervalsHandler, {keys} from "../../../services/utils/IntervalsHandler";

const NextMeeting = ({
  initialTimeUntilNextMeeting,
  nextMeetingTimes,
  currentTime,
  minutesUntilNextMeeting,
}) => {
  let daysUntilNextMeeting = Math.floor(initialTimeUntilNextMeeting / 24);
  let hoursUntilNextMeeting =
    initialTimeUntilNextMeeting - daysUntilNextMeeting * 24;

  let internalClock = 0;
  const [timeUntilNextMeeting, setTimeUntilNextMeeting] = useState(
    hoursUntilNextMeeting
  );
  const [daysStateUntilNextMeeting, setDaysUntilNextMeeting] =
    useState(daysUntilNextMeeting);
  const [minutesStateUntilNextMeeting, setMinutesUntilNextMeeting] =
    useState(minutesUntilNextMeeting);

  useEffect(() => {
    if (isValidDate(currentTime)) {

      const intervalId = IntervalsHandler[keys.NEXT_MEETING];
      
      let daysUntilNextMeetingTemp = Math.floor(
        initialTimeUntilNextMeeting / 24
      );
      let hoursUntilNextMeetingTemp =
        initialTimeUntilNextMeeting - daysUntilNextMeeting * 24;

      let minutesUntilNextMeetingTemp;
      if(nextMeetingTimes){
        minutesUntilNextMeetingTemp = dayjs(
          nextMeetingTimes.initialNextMeetingTime
        ).diff(currentTime, "minute");
      }


      setTimeUntilNextMeeting(hoursUntilNextMeetingTemp);
      setDaysUntilNextMeeting(daysUntilNextMeetingTemp);
      setMinutesUntilNextMeeting(minutesUntilNextMeetingTemp);

      if (intervalId !== null) {
        clearInterval(intervalId);
      }
      internalClock = currentTime.getTime();
      IntervalsHandler[keys.NEXT_MEETING] = setInterval(checkTimeUntilNextMeeting, 1800000);
    }
  }, [initialTimeUntilNextMeeting, currentTime, nextMeetingTimes]);

  const checkTimeUntilNextMeeting = () => {
    internalClock += 1800000;
    const internalClockDate = new Date(internalClock);
    let hoursUntilNextMeetingTemp = dayjs(
      nextMeetingTimes.initialNextMeetingTime
    ).diff(internalClockDate, "hour");
    const minutesUntilNextMeetingTemp = dayjs(
      nextMeetingTimes.initialNextMeetingTime
    ).diff(internalClockDate, "minute");

    let daysUntilNextMeetingTemp = Math.floor(initialTimeUntilNextMeeting / 24);
    hoursUntilNextMeetingTemp =
      initialTimeUntilNextMeeting - daysUntilNextMeeting * 24;
    setTimeUntilNextMeeting(hoursUntilNextMeetingTemp);
    setDaysUntilNextMeeting(daysUntilNextMeetingTemp);
    setMinutesUntilNextMeeting(minutesUntilNextMeetingTemp);
    //setTimeUntilNextMeeting(Math.round((nextMeetingTimes.initialNextMeetingTime.getTime() - internalClockDate.getTime())*0.001/60/60))
  };

  const getNextMeetingTimes = (daysStateUntilNextMeeting, timeUntilNextMeeting, minutesStateUntilNextMeeting) => {

    let timeNextMeeting = {
      days: "",
      hours: "",
      minutes: ""
    };

    if (daysStateUntilNextMeeting !== 0) {
      if (daysStateUntilNextMeeting === 1) {
        timeNextMeeting.days = `${daysStateUntilNextMeeting} giorno `;
      } else {
        timeNextMeeting.days = `${daysStateUntilNextMeeting} giorni `;
      }
    }

    if (timeUntilNextMeeting !== 0) {
      if (daysStateUntilNextMeeting > 0) {
        timeNextMeeting.hours = "e ";
      }
      
      if (timeUntilNextMeeting === 1) {
        timeNextMeeting.hours += `${timeUntilNextMeeting} ora`;
      } else {
        timeNextMeeting.hours += `${timeUntilNextMeeting} ore`;
      }
    }

    if (daysStateUntilNextMeeting === 0 && timeUntilNextMeeting === 0) {
      if (minutesStateUntilNextMeeting >= 0) {
        if (minutesStateUntilNextMeeting === 0) {
          timeNextMeeting.minutes =  'meno di un minuto';
        }
        if (minutesStateUntilNextMeeting === 1) {
          timeNextMeeting.minutes =  `${minutesStateUntilNextMeeting} minuto`;
        }
        if (minutesStateUntilNextMeeting > 1 ){
          timeNextMeeting.minutes =  `${minutesStateUntilNextMeeting} minuti`;
        }
      }
    }

    return `Prossima riunione tra ${timeNextMeeting.days} ${timeNextMeeting.hours} ${timeNextMeeting.minutes}`;

  }

  function isValidDate(date) {
    return date instanceof Date && !isNaN(date);
  }

  return (
    <div className="col-3 next-meeting bg-color-meeting-box">
      {nextMeetingTimes && minutesStateUntilNextMeeting >= 0? (
        <p className="center-white font-color-meeting-box font-family-primary">
          {getNextMeetingTimes(daysStateUntilNextMeeting, timeUntilNextMeeting, minutesStateUntilNextMeeting)}
        </p>
      ) : 
      (
        <p className="center-white font-color-meeting-box font-family-primary">
          Prossima riunione non pianificata
        </p>
      )}
    </div>
  );
};

NextMeeting.propTypes = {
  initialTimeUntilNextMeeting: PropTypes.number,
  nextMeetingTimes: PropTypes.object,
  currentTime: PropTypes.instanceOf(Date).isRequired,
  minutesUntilNextMeeting: PropTypes.number,
};

export default NextMeeting;

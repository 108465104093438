import theme from "../../../theme.json";

const getCSS = () => {
  let font;
  let fontSecondary;

  if (theme["font-google-source-primary"]) {
    font = `${theme["font-google-source-primary"]}
    .font-family-primary { font-family: '${theme["font-google-name-primary"]}'} `
  } else {
    font = `@font-face {
      font-family: 'font-family-primary';
      src: url("${theme["font-source-primary"]}")
        format("${theme["font-type-primary"]}");
    }
    .font-family-primary { font-family: 'font-family-primary'}`
  }

  if (theme["font-google-source-secondary"]) {
    fontSecondary = `${theme["font-google-source-secondary"]}
    .font-family-secondary { font-family: '${theme["font-google-name-secondary"]}'} `
  } else {
    fontSecondary = `@font-face {
      font-family: 'font-family-secondary';
      src: url("${theme["font-source-secondary"]}")
        format("${theme["font-type-secondary"]}");
    }
    .font-family-secondary { font-family: 'font-family-secondary'}`
  }

  return `
          ${font}
          ${fontSecondary}
          .font-color-primary { color: ${theme["font-color-primary"]}}
          .font-color-secondary { color: ${theme["font-color-secondary"]}}
          .font-color-room-title { color: ${theme["font-color-room-title"]}}
          .font-color-meeting-title { color: ${theme["font-color-meeting-title"]}}
          .font-color-sidebar-busy { color: ${theme["font-color-sidebar-busy"]}}
          .font-color-sidebar-free { color: ${theme["font-color-sidebar-free"]}}
          .font-color-scheduler { color: ${theme["font-color-scheduler"]}} 
          .font-color-meeting-box { color: ${theme["font-color-meeting-box"]}}
          .font-color-progress-bar { color: ${theme["font-color-progress-bar"]}}

          .font-size-room-title-busy { font-size: ${theme["font-size-room-title-busy"]}}
          .font-size-room-title-free { font-size: ${theme["font-size-room-title-free"]}}
          .font-size-meeting-title { font-size: ${theme["font-size-meeting-title"]}}

          .bg-color-primary { background: ${theme["bg-color-primary"]}}
          .bg-color-meeting-box { background: ${theme["bg-color-meeting-box"]}}
          .bg-color-sidebar-busy { background: ${theme["bg-color-sidebar-busy"]}}
          .bg-color-sidebar-free { background: ${theme["bg-color-sidebar-free"]}}
          .bg-color-progress-bar-active::before { background: ${theme["bg-color-progress-bar-active"]}}
          .bg-color-progress-bar-default { background: ${theme["bg-color-progress-bar-default"]}}
          .bg-color-hours-slot { background: ${theme["bg-color-hours-slot"]}}
          .bg-color-meetings-slot { background: ${theme["bg-color-meetings-slot"]}}
          .bg-color-scheduler-arrow { background: ${theme["bg-color-scheduler-arrow"]}}

          .scheduler-border { border-top: ${theme["border-width-scheduler"]} solid ${theme["border-color-scheduler"]};
                              border-right: ${theme["border-width-scheduler"]} solid ${theme["border-color-scheduler"]}}

          .scheduler-border-container
           { border-bottom: ${theme["border-width-scheduler"]} solid ${theme["border-color-scheduler"]};
             border-left: ${theme["border-width-scheduler"]} solid ${theme["border-color-scheduler"]}}


          .scheduler-divider-width { width: ${theme["scheduler-divider-width"]}}

          .logo { margin-left: ${theme['logo-margin-left']}}
          
          .arrow-color { border-left-color: ${theme['arrow-color']}}
          .red-line { height: ${theme['now-indicator-height']}}
        }`;
};

exports.getCSS = getCSS;

export const keys = {
  PROGRESS_BAR: "PROGRESS_BAR",
  SCHEDULER: "SCHEDULER",
  NEXT_MEETING: "NEXT_MEETING",
};


let IntervalsHandler = {};


export default IntervalsHandler;

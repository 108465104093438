import i18next from "i18next";

const i18nextInit = (langSelected, locales, languages) => {
  const configI18n = {
    debug: process.env.NODE_ENV === "dev",
    lng: langSelected,
    resources: {},
  };

  configI18n.resources[langSelected] = {};
  configI18n.resources[langSelected].translation = { ...locales, ...languages };

  i18next.init(configI18n);
  return i18next;
};

export default i18nextInit;

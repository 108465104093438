import memoize from "../../../services/utils/memoize";
import "./scheduler.scss";
import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import IntervalsHandler, {
  keys,
} from "../../../services/utils/IntervalsHandler";
import MeetingBlock from "./MeetingBlock";

const dayjs = require("dayjs");

let incrementedTime = 0;

const Scheduler = ({ currentTime, events }) => {
  // eslint-disable-next-line no-unused-vars
  const [scrollingAmount, setScrollingAmount] = useState(0);
  const INITIAL_SCHEDULER_HOUR = 8;
  const FINAL_SCHEDULER_HOUR = 20;
  const DELAY = 30000;
  const [pixelPerMinute, setPixelPerMinute] = useState(0);
  const [cellWidth, setCellWidth] = useState(0);
  const [initialHeightOffset, setInitialHeightOffset] = useState(0);
  const [initialLeftOffset, setInitialLeftOffset] = useState(0);
  const [firstCellLeftOffset, setFirstCellLeftOffset] = useState(0);

  const [eventsArray, setEventsArray] = useState([]);

  useEffect(() => {
    const secondRowComplete = document.getElementsByClassName(
      "hour-container d-flex flex-row reduced-height"
    );
    const firstRightCell = document.getElementsByClassName(
      "w-75 border-top p-3"
    );
    const secondLeftCell = document.getElementsByClassName(
      "w-25 border-top-bold border-right p-1 text-secondary text-right"
    );

    const cellWidthTemp = firstRightCell[0].getBoundingClientRect().width;
    setCellWidth(cellWidthTemp);
    const cellHeight = secondRowComplete[0].getBoundingClientRect().height;
    const initialHeightOffsetTemp =
      firstRightCell[0].getBoundingClientRect().height;
    setInitialHeightOffset(initialHeightOffsetTemp);
    const initialLeft = secondLeftCell[0].offsetLeft;
    setFirstCellLeftOffset(initialLeft);
    const initialLeftOffsetTemp = secondLeftCell[0].offsetWidth + initialLeft;
    setInitialLeftOffset(initialLeftOffsetTemp);
    const pixelPerMinuteTemp = cellHeight / 30;
    setPixelPerMinute(pixelPerMinuteTemp);
  }, []);

  useEffect(() => {
    document.querySelectorAll(".meeting-block").forEach((el) => el.remove());
    const eventsArrayTemp = createEventsArray();
    setEventsArray(eventsArrayTemp);
  }, [events]);

  useEffect(() => {
    const intervalId = IntervalsHandler[keys.SCHEDULER];

    incrementedTime = currentTime.getTime();
    LineAndTriangleAnimation(currentTime.getTime());
    if (intervalId !== null) {
      clearInterval(intervalId);
    }
    incrementedTime = currentTime.getTime();
    IntervalsHandler[keys.SCHEDULER] = setInterval(
      displayLineAndTraingle,
      DELAY
    );
  }, [currentTime]);

  useEffect(() => {
    const intervalId = IntervalsHandler[keys.SCHEDULER];

    incrementedTime = currentTime.getTime();
    LineAndTriangleAnimation(currentTime.getTime());
    if (intervalId !== null) {
      clearInterval(intervalId);
    }
    incrementedTime = currentTime.getTime();
    IntervalsHandler[keys.SCHEDULER] = setInterval(
      displayLineAndTraingle,
      DELAY
    );
  }, [cellWidth]);

  const displayLineAndTraingle = () => {
    incrementedTime += DELAY;
    LineAndTriangleAnimation(incrementedTime);
  };

  const LineAndTriangleAnimation = (currentTime) => {
    const currentTimeDate = new Date(currentTime);
    let retrieveCurrentHour = dayjs(currentTimeDate).get("hour");
    let retrieveCurrentMinutes = dayjs(currentTimeDate).get("minute");
    let initialMeetingTime;

    if (retrieveCurrentHour < INITIAL_SCHEDULER_HOUR) {
      setScrollingAmount(retrieveCurrentHour - INITIAL_SCHEDULER_HOUR);
      initialMeetingTime =
        retrieveCurrentHour * 60 +
        retrieveCurrentMinutes -
        retrieveCurrentHour * 60;
    }
    if (retrieveCurrentHour > FINAL_SCHEDULER_HOUR) {
      setScrollingAmount(retrieveCurrentHour - FINAL_SCHEDULER_HOUR);
      initialMeetingTime =
        (FINAL_SCHEDULER_HOUR - INITIAL_SCHEDULER_HOUR) * 60 +
        retrieveCurrentMinutes;
    }
    if (
      retrieveCurrentHour >= INITIAL_SCHEDULER_HOUR &&
      retrieveCurrentHour <= FINAL_SCHEDULER_HOUR
    ) {
      setScrollingAmount(0);
      initialMeetingTime =
        retrieveCurrentHour * 65.5 +
        retrieveCurrentMinutes -
        INITIAL_SCHEDULER_HOUR * 60;
    }

    const initialTopOffset =
      initialHeightOffset + initialMeetingTime * pixelPerMinute;

    let redLines = document.getElementsByClassName("red-line");
    while (redLines[0]) {
      redLines[0].parentNode.removeChild(redLines[0]);
    }
    let redTriangles = document.getElementsByClassName("red-triangle");
    while (redTriangles[0]) {
      redTriangles[0].parentNode.removeChild(redTriangles[0]);
    }

    var redLineElement = document.createElement("div");
    redLineElement.classList.add("red-line");
    redLineElement.classList.add("bg-color-scheduler-arrow");

    var redTriangleElement = document.createElement("div");
    redTriangleElement.classList.add("red-triangle");
    redTriangleElement.classList.add("arrow-color");

    redLineElement.style.position = "absolute";
    redLineElement.style.width = cellWidth;
    redLineElement.style.top = initialTopOffset;
    redLineElement.style.left = initialLeftOffset;
    redLineElement.style.zIndex = 2;

    redTriangleElement.style.position = "absolute";
    redTriangleElement.style.top = initialTopOffset - 7;
    redTriangleElement.style.left = firstCellLeftOffset;
    if (cellWidth !== 0 && initialTopOffset !== 0 && initialLeftOffset !== 0) {
      document.getElementById("all-container").appendChild(redLineElement);
      document.getElementById("all-container").appendChild(redTriangleElement);
    }
  };

  const createEventsArray = () => {
    let eventsArray = [];
    if (events) {
      events.forEach((event) => {
        let eventDate;
        if (event.custom_subject) {
          let customSubject;
          if (event.custom_subject.length > 25) {
            customSubject = `<span>${event.custom_subject.substring(0, 24) + '...'}</span>`;
          } else {
            customSubject = event.custom_subject;
          }
          eventDate = {
            start_dt: new Date(event.start),
            end_dt: new Date(event.end),
            subject: customSubject,
            isAllDay: event.isAllDay,
          };
        } else {
          eventDate = {
            start_dt: new Date(event.start),
            end_dt: new Date(event.end),
            subject: event.subject,
            isAllDay: event.isAllDay,
          };
        }
        eventsArray.push(eventDate);
      });
    }
    return eventsArray;
  };

  const getHours = memoize(() => {
    let hours = [];
    for (let i = INITIAL_SCHEDULER_HOUR + scrollingAmount; i < 24; i++) {
      let val;
      if (i.toString().length === 1) val = "0" + i + ":00";
      else val = i + ":00";
      hours.push({ id: i, val: val });
    }
    return hours;
  });

  const hours = getHours();
  return (
    <div id="all-container" className="scheduler font-color-scheduler bg-color-meetings-slot scheduler-border-container font-family-primary">
      <div className="hour-container d-flex flex-row">
        <div className="divider scheduler-divider-width" />
        <div className="w-25 border-right scheduler-border p-3 text-right bg-color-hours-slot">
          Tutto il giorno
        </div>
        <div className="divider scheduler-divider-width" />
        <div className="w-75 scheduler-border p-3 bg-color-meetings-slot"></div>
        <div className="divider scheduler-divider-width" />
      </div>
      {hours.map((hour, idx) => {
        return (
          <div key={`${hour.id}`}>
            <div
              key={`${hour.id}-${idx}-1`}
              className="hour-container d-flex flex-row reduced-height"
            >
              <div key={`${hour.id}-${idx}-1-1`} className="divider scheduler-divider-width" />
              <div
                key={`${hour.id}-${idx}-1-hour`}
                className="w-25 border-top-bold border-right p-1 text-secondary font-color-secondary text-right sx-position"
              >
                {hour.val}
                <div />
              </div>
              <div key={`${hour.id}-${idx}-1-2`} className="divider scheduler-divider-width" />
              <div
                key={`${hour.id}-${idx}-1-meet`}
                className="w-75 border-top-bold p-1 bg-dynamic-color"
              ></div>
              <div key={`${hour.id}-${idx}-1-3`} className="divider scheduler-divider-width" />
            </div>
            <div
              key={`${hour.id}-${idx}-2`}
              className="hour-container d-flex flex-row reduced-height"
            >
              <div key={`${hour.id}-${idx}-2-1`} className="divider scheduler-divider-width" />
              <div
                key={`${hour.id}-${idx}-2-hour`}
                className="w-25 border-top p-3 border-top-dotted text-secondary sx-position"
              ></div>
              <div key={`${hour.id}-${idx}-2-2`} className="divider scheduler-divider-width" />
              <div
                key={`${hour.id}-${idx}-2-meet`}
                className="w-75 border-top p-3 border-top-dotted bg-dynamic-color"
              ></div>
              <div key={`${hour.id}-${idx}-2-3`} className="divider scheduler-divider-width" />
            </div>
          </div>
        );
      })}
      {eventsArray.map((event, index) => {
        return (
          <MeetingBlock
            key={index}
            pixelPerMinute={pixelPerMinute}
            initialHeightOffset={initialHeightOffset}
            cellWidth={cellWidth}
            initialLeftOffset={initialLeftOffset}
            event={event}
            currentTime={currentTime}
            scrollingAmount={scrollingAmount}
          />
        );
      })}
    </div>
  );
};

Scheduler.propTypes = {
  currentTime: PropTypes.instanceOf(Date).isRequired,
  events: PropTypes.array,
};

export default Scheduler;

// COMPONENTS

// STYLE
import "./meetingTitle.css";
import "../../../styles/layout.scss";

// THIS IS A GLOBAl IMPORT: DON'T TOUCH IT!!
/* eslint-disable no-unused-vars */
import i18n from "i18next";
import PropTypes from "prop-types";

const MeetingTitle = ({ currentMeetingTitle }) => {
  return (
    <div className="row h-25">
      <div className="col-12">
        <p className="current-meeting-title font-color-meeting-title font-size-meeting-title font-family-primary">
          {currentMeetingTitle? currentMeetingTitle.toUpperCase() : ""}
        </p>
      </div>
    </div>
  );
};

MeetingTitle.propTypes = {
  currentMeetingTitle: PropTypes.string.isRequired,
};

export default MeetingTitle;

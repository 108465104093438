import "./scheduler.scss";
import PropTypes from "prop-types";
const dayjs = require("dayjs");

const MeetingBlock = ({
  pixelPerMinute,
  initialHeightOffset,
  cellWidth,
  initialLeftOffset,
  event,
  currentTime,
  scrollingAmount,
}) => {
  const INITIAL_SCHEDULER_HOUR = 8;
  const FINAL_SCHEDULER_HOUR = 20;
  const TABLE_BORDER_SIZE = 1;
  const startTime = dayjs(event.start_dt).format("HH:mm");
  const endTime = dayjs(event.end_dt).format("HH:mm");

  const stylingBlock = (blockHeight, initialTopOffset, innerHTML) => {
    let blockToInsert = document.createElement("div");
    blockToInsert.classList.add("meeting-block");
    blockToInsert.style.paddingLeft = "6px";
    blockToInsert.style.background = "black";
    blockToInsert.style.border = "1px solid white";
    blockToInsert.style.color = "white";
    blockToInsert.style.borderRadius = "4px";
    blockToInsert.style.position = "absolute";
    blockToInsert.style.height = blockHeight;
    blockToInsert.style.width = cellWidth;
    blockToInsert.style.top = initialTopOffset;
    blockToInsert.style.left = initialLeftOffset;
    blockToInsert.innerHTML = innerHTML;
    document.getElementById("root").appendChild(blockToInsert);
  };

  let retrieveStartHour = dayjs(event.start_dt).get("hour");
  let retrieveStartMinutes = dayjs(event.start_dt).get("minute");
  let retrieveEndHour = dayjs(event.end_dt).get("hour");
  // let retrieveEndMinutes = dayjs(event.end_dt).get("minute");
  let difference = dayjs(event.end_dt).diff(event.start_dt) / 1000 / 60;

  if (
    event.isAllDay &&
    dayjs(event.start_dt).format("DD/MM/YY") ===
    dayjs(event.end_dt).format("DD/MM/YY")
  ) {
    const initialTopOffset = TABLE_BORDER_SIZE;
    const innerHTML = `<span>${event.subject}</span>`;
    return stylingBlock(initialHeightOffset, initialTopOffset, innerHTML);
  }

  if (
    dayjs(event.start_dt).format("DD/MM/YY") ===
    dayjs(event.end_dt).format("DD/MM/YY") &&
    retrieveEndHour - retrieveStartHour > 12
  ) {
    const initialTopOffset = TABLE_BORDER_SIZE;
    const innerHTML = `<span>${startTime} - ${endTime}</span><br/><span>${event.subject}</span>`;
    return stylingBlock(initialHeightOffset, initialTopOffset, innerHTML);
  }

  const remainingDays = dayjs(event.end_dt).diff(dayjs(currentTime), "d");

  if (remainingDays > 0) {
    const initialDay = dayjs(event.start_dt).format("DD/MM/YY");
    const endDay = dayjs(event.end_dt).format("DD/MM/YY");
    const initialTopOffset = TABLE_BORDER_SIZE;
    const innerHTML = `<span>${initialDay} - ${endDay}</span><br/><span>${event.subject}</span>`;

    stylingBlock(initialHeightOffset, initialTopOffset, innerHTML);
  } else {

    if (retrieveStartHour < INITIAL_SCHEDULER_HOUR + scrollingAmount) {
      retrieveStartHour = INITIAL_SCHEDULER_HOUR;
      let startTimeTruncate = dayjs(event.start_dt).hour(retrieveStartHour);
      difference = dayjs(event.end_dt).diff(startTimeTruncate) / 1000 / 60;
    }

    if (retrieveEndHour > FINAL_SCHEDULER_HOUR + scrollingAmount) {
      retrieveEndHour = FINAL_SCHEDULER_HOUR + 1;
      let endTimeTruncate = dayjs(event.end_dt).hour(retrieveEndHour);
      difference = dayjs(endTimeTruncate).diff(event.start_dt) / 1000 / 60;
    }

    const initialMeetingTime =
      retrieveStartHour * 60 +
      retrieveStartMinutes -
      (INITIAL_SCHEDULER_HOUR + scrollingAmount) * 60;
    const initialTopOffset =
      initialHeightOffset + initialMeetingTime * pixelPerMinute + 57.5;
    const duration = difference;

    if (duration < 30) {
      const blockHeight = duration * pixelPerMinute;
      const innerHTML = ``;
      stylingBlock(blockHeight, initialTopOffset, innerHTML);
    }

    if (duration >= 30 && duration < 60) {
      const blockHeight = duration * pixelPerMinute;
      const innerHTML = `<span>${event.subject}</span>`;
      stylingBlock(blockHeight, initialTopOffset, innerHTML);
    }
    if (duration >= 60) {
      const blockHeight = duration * pixelPerMinute + TABLE_BORDER_SIZE;
      const innerHTML = `<span>${startTime} - ${endTime}</span><br/><span>${event.subject}</span>`;
      stylingBlock(blockHeight, initialTopOffset, innerHTML);
    }
  }
};

MeetingBlock.propTypes = {
  pixelPerMinute: PropTypes.number,
  initialHeightOffset: PropTypes.number,
  cellWidth: PropTypes.number,
  initialLeftOffset: PropTypes.number,
  event: PropTypes.object,
  currentTime: PropTypes.instanceOf(Date).isRequired,
  scrollingAmount: PropTypes.number,
};

export default MeetingBlock;

{
    "font-source-primary": "https://fonts.gstatic.com/s/roboto/v30/KFOmCnqEu92Fr1Mu72xKOzY.woff2",
    "font-type-primary": "truetype",
    "font-source-secondary": "./public/fonts/Roboto-Regular.ttf",
    "font-type-secondary": "truetype",
    "font-google-source-primary": "@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');",
    "font-google-name-primary": "Roboto",
    "font-google-source-secondary": "@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');",
    "font-google-name-secondary": "Roboto",
    "font-color-primary": "#212529",
    //"font-color-secondary": "#f5f5f5",
    "font-color-secondary": "white !important",
    "font-color-room-title": "#24bebd",
    "font-color-meeting-title": "#24bebd",
    "font-color-sidebar-busy": "#f5f5f5",
    "font-color-sidebar-free": "#f5f5f5",
    "font-color-meeting-box": "#f5f5f5",
    "font-color-scheduler": "#e4e4e4",
    "font-color-progress-bar": "#24bebd",
    "font-size-room-title-busy": "45px",
    "font-size-room-title-free": "53px",
    "font-size-meeting-title": "45px",
    //"bg-color-primary": "linear-gradient(180deg,#393939 0%,#000 100%)",
    "bg-color-primary": "#393939",
    "bg-color-meeting-box": "#24bebd !important",
    "bg-color-sidebar-busy": "#97004b",
    "bg-color-sidebar-free": "#24bebd",
    "bg-color-progress-bar-active": "#24bebd",
    "bg-color-progress-bar-default": "#e4e4e4",
    "bg-color-hours-slot": "#1f1f1f",
    "bg-color-meetings-slot": "#444444",
    "bg-color-scheduler-arrow": "white !important",
    "arrow-color": "white !important",
    "now-indicator-height": "2px !important",
    "border-width-scheduler": "1px",
    "border-color-scheduler": "#999999",
    "scheduler-divider-width": "1px !important",
    "logo-margin-left": 0
}
// COMPONENTS
import { useMemo } from "react";

// STYLE
import "./ReservedRoom.css";
import "../../styles/layout.scss";

// THIS IS A GLOBAl IMPORT: DON'T TOUCH IT!!
/* eslint-disable no-unused-vars */
import i18n from "i18next";
import PropTypes from "prop-types";
import { useEffect, useRef, useState } from "react";
import * as dayjs from "dayjs";
import IntervalsHandler, { keys } from "../../services/utils/IntervalsHandler";
import Logo from "../../components/ui/Logo/Logo";
import RoomTitle from "../../components/ui/RoomTitle/RoomTitle";

const ReservedRoom = ({ logo, title }) => {
  const visibleLogo = useMemo(() => {
    return <Logo logoImage={logo} reserved={true} />;
  }, [logo]);

  const formattedMail = useMemo(() => {
    if (title) return <RoomTitle roomTitle={title} isBusy={false} />;
    else return <RoomTitle roomTitle={""} isBusy={false} />;
  }, [title]);

  return (
    <div className="container-fluid bg-color-primary">
      <div className="row h-33">
        <div className="col-1" />
        <div className="col-10">{visibleLogo}</div>
      </div>
      <div className="row h-27">
        <div className="col-1" />
        <div className="col-10 reservedroom-title-container centerTitle">
          {formattedMail}
        </div>
      </div>
      <div className="row h-27"></div>
      <div className="row h-12"></div>
    </div>
  );
};

ReservedRoom.propTypes = {
  logo: PropTypes.string,
  name: PropTypes.string,
};

export default ReservedRoom;

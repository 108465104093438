{
  "Languages": {
    "it": {
      "COUNTRY_LABEL": "Italia",
      "LANGUAGE_LABEL": "Italiano",
      "FLAG_IMG_URL": ""
    },
    "en": {
      "COUNTRY_LABEL": "UK",
      "LANGUAGE_LABEL": "English",
      "FLAG_IMG_URL": ""
    },
    "de": {
      "COUNTRY_LABEL": "Deutschland",
      "LANGUAGE_LABEL": "Deutsch",
      "FLAG_IMG_URL": ""
    }
  }
}

// COMPONENTS

// STYLE
import "./logo.css";
import "../../../styles/layout.scss";

// THIS IS A GLOBAl IMPORT: DON'T TOUCH IT!!
/* eslint-disable no-unused-vars */
import i18n from "i18next";
import PropTypes from "prop-types";

const Logo = ({ logoImage, reserved }) => {
  return (
    <div className="col-4">
      {logoImage ? (
        <img className={reserved? "reservedLogo" : "logo"} src={logoImage} alt="JuventusLogo" />
      ) : (
        <></>
      )}
    </div>
  );
};
/*<img className="logo" src={`data:image/jpeg;base64,${logoImage}`} alt="JuventusLogo"/>*/

Logo.propTypes = {
  logoImage: PropTypes.string,
};

export default Logo;

// COMPONENTS

// STYLE
import "./nextMeetingInfos.css";
import "../../../styles/layout.scss";
import "../SidebarAvailability/sidebarAvailability.css";

// THIS IS A GLOBAl IMPORT: DON'T TOUCH IT!!
/* eslint-disable no-unused-vars */
import i18n from "i18next";
import PropTypes from "prop-types";

const NextMeetingInfos = ({
  nextMeetingSchedule,
  nextMeetingTitle,
  initialNextMeetingDay,
}) => {
  return (
    <div className="col-8 next-meeting bg-color-meeting-box">
      {nextMeetingTitle ? (
        <>
          <p className="center-white font-color-meeting-box font-family-primary">
            <span>{initialNextMeetingDay} &nbsp;</span> dalle{" "}
            {nextMeetingSchedule.initialNextMeetingTime} -{" "}
            {nextMeetingSchedule.finalNextMeetingTime}
          </p>
          <p className="center-white font-color-meeting-box font-family-primary">{nextMeetingTitle}</p>
        </>
      ) : (
        <p className="center-white font-color-meeting-box font-family-primary">
          La prossima riunione non è stata ancora pianificata
        </p>
      )}
    </div>
  );
};

NextMeetingInfos.propTypes = {
  nextMeetingSchedule: PropTypes.object,
  nextMeetingTitle: PropTypes.string,
  initialNextMeetingDay: PropTypes.string,
};

export default NextMeetingInfos;

// COMPONENTS

// STYLE
import "./roomTitle.css";
import "../../../styles/layout.scss";

// THIS IS A GLOBAl IMPORT: DON'T TOUCH IT!!
/* eslint-disable no-unused-vars */
import i18n from "i18next";
import PropTypes from "prop-types";

const RoomTitle = ({ roomTitle, isBusy }) => {
  return (
    <>
      <p className={`room-title font-family-primary font-color-room-title  ${isBusy === true ? "font-size-room-title-busy" : "font-size-room-title-free"}`}>
        {roomTitle.toUpperCase()}
      </p>
    </>
  );
};

RoomTitle.propTypes = {
  roomTitle: PropTypes.string.isRequired,
  isBusy: PropTypes.bool.isRequired,
};

export default RoomTitle;

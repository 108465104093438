import { useMemo, useState, useEffect } from "react";
// COMPONENTS
import SidebarAvailability from "../../components/ui/SidebarAvailability/SidebarAvailability";
import NextMeeting from "../../components/ui/NextMeeting/NextMeeting";
import MeetingTitle from "../../components/ui/MeetingTitle/MeetingTitle";
import Logo from "../../components/ui/Logo/Logo";
import NextMeetingInfos from "../../components/ui/NextMeetingInfos/NextMeetingInfos";
import RoomTitle from "../../components/ui/RoomTitle/RoomTitle";
import ProgressBar from "../../components/ui/ProgressBar/ProgressBar";
import Scheduler from "../../components/ui/Scheduler/Scheduler";
import * as dayjs from "dayjs";

// STYLE
import "./welcome.css";
import "../../styles/layout.scss";

// THIS IS A GLOBAl IMPORT: DON'T TOUCH IT!!
/* eslint-disable no-unused-vars */
import i18n from "i18next";
import PropTypes from "prop-types";

const Welcome = ({ events }) => {
  const [isBusy, setIsBusy] = useState(false);

  let currentTime,
    currentMeetingTimes,
    initialNextMeetingHours,
    initialNextMeetingDay,
    currentMeetingSubject,
    finalNextMeetingHours,
    nextMeetingTitle,
    nextMeetingTimes,
    timeUntilNextMeeting,
    minutesUntilNextMeeting,
    nextMeetingSchedule,
    logo,
    name,
    allMeetings;

  if (events) {
    allMeetings = events.meetings;
    currentTime = new Date(events.serverDt);
    logo = events.logo;
    name = events.name;
    if (events.currentMeeting) {
      if(events.currentMeeting.custom_subject){
        if (events.currentMeeting.custom_subject.length > 25){
          currentMeetingSubject = events.currentMeeting.custom_subject.substring(0,24) + '...';
        }
        else{
          currentMeetingSubject = events.currentMeeting.custom_subject;
        }
      } else {
        currentMeetingSubject = events.currentMeeting.subject;
      }
    }
    if (events.currentMeeting) {
      currentMeetingTimes = {
        initialCurrentMeetingTime: new Date(events.currentMeeting.start),
        finalCurrentMeetingTime: new Date(events.currentMeeting.end),
      };
    }
    if (events.nextMeeting) {
      if(events.nextMeeting.custom_subject) {
        if (events.nextMeeting.custom_subject.length > 25){
          nextMeetingTitle = events.nextMeeting.custom_subject.substring(0,24) + '...';
        }
        else{
          nextMeetingTitle = events.nextMeeting.custom_subject;
        }
      } else {
        nextMeetingTitle = events.nextMeeting.subject;
      }
      
      initialNextMeetingDay = dayjs(events.nextMeeting.start).format('DD/MM/YYYY');
      initialNextMeetingHours = dayjs(events.nextMeeting.start).format('HH:mm');
      finalNextMeetingHours = dayjs(events.nextMeeting.end).format('HH:mm');

      nextMeetingTimes = {
        initialNextMeetingTime: new Date(events.nextMeeting.start),
        finalNextMeetingTime: new Date(events.nextMeeting.end),
      };

      timeUntilNextMeeting = dayjs(events.nextMeeting.start).diff(
        new Date(currentTime),
        "hour"
      );
      minutesUntilNextMeeting = dayjs(events.nextMeeting.start).diff(
        new Date(currentTime),
        "minute"
      );
      nextMeetingSchedule = {
        initialNextMeetingTime: initialNextMeetingHours,
        finalNextMeetingTime: finalNextMeetingHours,
      };
    }
  }

  useEffect(() => {
    if (currentMeetingTimes) {
      if (
        currentTime >= currentMeetingTimes.initialCurrentMeetingTime &&
        currentMeetingTimes.finalCurrentMeetingTime >= currentTime
      )
        setIsBusy(true);
      else setIsBusy(false);
    }
  }, [currentMeetingTimes, currentTime]);

  const visibleMeetingTitle = useMemo(() => {
    if (events) {
      if (events.currentMeeting)
        return <MeetingTitle currentMeetingTitle={currentMeetingSubject} />;
    } else return <MeetingTitle currentMeetingTitle={""} />;
  }, [currentMeetingSubject]);

  const visibleLogo = useMemo(() => {
    return <Logo logoImage={logo} reserved={false}/>;
  }, [logo]);

  const formattedMail = useMemo(() => {
    if (name) return <RoomTitle roomTitle={name} isBusy={isBusy} />;
    else return <RoomTitle roomTitle={""} isBusy={isBusy} />;
  }, [name, isBusy]);

  const isBusyCallback = (isStillBusy) => {
    setIsBusy(isStillBusy);
  };

  const ControlledProgressBar = useMemo(() => {
    return (
      <ProgressBar
        currentMeetingTimes={currentMeetingTimes}
        currentTime={currentTime}
        isBusyCallback={isBusyCallback}
      />
    );
  }, [currentTime, currentMeetingTimes]);

  return (
    <div className="container-fluid bg-color-primary">
      <div className="row h-100">
        <SidebarAvailability isBusy={isBusy} />
        <div className="col-8">
          <div className="row h-25">
            {visibleLogo}
            {isBusy ? (
              <div className="col-8 room-title-container">{formattedMail}</div>
            ) : (
              <></>
            )}
          </div>
          {!isBusy ? (
            <div className="row h-50">{formattedMail}</div>
          ) : (
            <div className="row h-10"></div>
          )}

          {isBusy ? <>{ControlledProgressBar}</> : <></>}

          {isBusy ? visibleMeetingTitle : <></>}

          <div className="row h-25">
            <NextMeeting
              initialTimeUntilNextMeeting={timeUntilNextMeeting}
              nextMeetingTimes={nextMeetingTimes}
              currentTime={currentTime}
              minutesUntilNextMeeting = {minutesUntilNextMeeting}
            />
            <NextMeetingInfos
              nextMeetingSchedule={nextMeetingSchedule}
              nextMeetingTitle={nextMeetingTitle}
              initialNextMeetingDay={initialNextMeetingDay}
            />
          </div>
        </div>
        <div className="col-3">
          <Scheduler currentTime={currentTime} events={allMeetings} />
        </div>
      </div>
    </div>
  );
};

Welcome.propTypes = {
  events: PropTypes.object.isRequired,
};

export default Welcome;

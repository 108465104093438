// COMPONENTS
//import intervalTimer from "../../../services/utils/intervalTimer";
// STYLE
import "./progressBar.css";
import "../../../styles/layout.scss";

// THIS IS A GLOBAl IMPORT: DON'T TOUCH IT!!
/* eslint-disable no-unused-vars */
import i18n from "i18next";
import PropTypes from "prop-types";
import { useEffect, useRef, useState } from "react";
import IntervalsHandler, {keys} from "../../../services/utils/IntervalsHandler";
const dayjs = require('dayjs');

const ProgressBar = ({ currentMeetingTimes, currentTime, isBusyCallback }) => {
  const progressBarRef = useRef(null);
  const progressBarDotRef = useRef(null);
  let incrementedTime = 0;
  let initialMeeting, finalMeeting;
  let days;

  if (currentMeetingTimes) {
    const initialMeetingDay = dayjs(currentMeetingTimes.initialCurrentMeetingTime).get('date');
    const finalMeetingDay = dayjs(currentMeetingTimes.finalCurrentMeetingTime).get('date');
    const days = finalMeetingDay - initialMeetingDay;

    if(days > 0) {
      let currentDay = dayjs(currentTime).get('date');
      let firstDay = currentDay - initialMeetingDay;
      let remainingDays = finalMeetingDay - currentDay;

      if (firstDay !== 0) {
        initialMeeting = dayjs(currentMeetingTimes.initialCurrentMeetingTime).format('DD/MM/YY');
      } else {
        initialMeeting = currentMeetingTimes.initialCurrentMeetingTime.getHours().toString() + ":";
        if (currentMeetingTimes.initialCurrentMeetingTime.getMinutes() <= 9) {
          initialMeeting = initialMeeting + "0" + currentMeetingTimes.initialCurrentMeetingTime.getMinutes();
        } else {
          initialMeeting = initialMeeting + currentMeetingTimes.initialCurrentMeetingTime.getMinutes();
        }
      }

      if (remainingDays > 0) {
        finalMeeting = dayjs(currentMeetingTimes.finalCurrentMeetingTime).format('DD/MM/YY');
      } else {
        finalMeeting = currentMeetingTimes.finalCurrentMeetingTime.getHours().toString() + ":";
        if (currentMeetingTimes.finalCurrentMeetingTime.getMinutes() <= 9) {
          finalMeeting = finalMeeting + "0" + currentMeetingTimes.finalCurrentMeetingTime.getMinutes();
        } else {
          finalMeeting = finalMeeting + currentMeetingTimes.finalCurrentMeetingTime.getMinutes();
        }
      }
    } else {
      initialMeeting =
      currentMeetingTimes.initialCurrentMeetingTime.getHours().toString() + ":";
      if (currentMeetingTimes.initialCurrentMeetingTime.getMinutes() <= 9)
        initialMeeting = initialMeeting + "0" + currentMeetingTimes.initialCurrentMeetingTime.getMinutes();
      else
        initialMeeting =
          initialMeeting +
          currentMeetingTimes.initialCurrentMeetingTime.getMinutes();

      finalMeeting =
        currentMeetingTimes.finalCurrentMeetingTime.getHours().toString() + ":";
      if (currentMeetingTimes.finalCurrentMeetingTime.getMinutes() <= 9) {
        finalMeeting = finalMeeting + "0" + currentMeetingTimes.finalCurrentMeetingTime.getMinutes();
      } else {
        finalMeeting = finalMeeting + currentMeetingTimes.finalCurrentMeetingTime.getMinutes();
      }
    }
  }

  useEffect(() => {
    ProgressBarAnimation(currentMeetingTimes, currentTime);
    
    const intervalId = IntervalsHandler[keys.PROGRESS_BAR];
    if (intervalId) {
      clearInterval(intervalId);
    }
    incrementedTime = currentTime.getTime();
    IntervalsHandler[keys.PROGRESS_BAR] = setInterval(displayTime, 20000);
  }, [currentMeetingTimes, currentTime]);

  const displayTime = () => {
    incrementedTime += 20000;
    if (currentMeetingTimes) {
      if (incrementedTime > currentMeetingTimes.finalCurrentMeetingTime) {
        isBusyCallback(false);
      }
      ProgressBarAnimation(currentMeetingTimes, incrementedTime);
    } else {
      isBusyCallback(false);
    }
  };

  const ProgressBarAnimation = (currentMeetingTimes, currentTime) => {
    if (currentMeetingTimes) {
      const meetingDuration =
        currentMeetingTimes.finalCurrentMeetingTime -
        currentMeetingTimes.initialCurrentMeetingTime;
      const difference =
        currentMeetingTimes.finalCurrentMeetingTime - currentTime;
      const meetingProgress = meetingDuration - difference;
      const initialProgress = (meetingProgress / meetingDuration) * 100;

      const progressBarElement = progressBarRef.current;
      const progressBarDotElement = progressBarDotRef.current;
      if (progressBarElement)
        progressBarElement.style.setProperty("--width", initialProgress);
      if (progressBarDotElement)
        progressBarDotElement.style.setProperty("--left", initialProgress);
    } else {
      isBusyCallback(false);
    }
  };

  if (!currentMeetingTimes) {
    return null;
  }

  return (
    <div className="row h-15">
      <div className="col-2 center-progress-bar-items right-current-time font-color-progress-bar font-family-primary">
        <p className={days > 0 ? "current-days" : "current-time"}>{initialMeeting}</p>
      </div>
      <div className="col-8 center-progress-bar-items center-progress-bar">
        <div ref={progressBarRef} className="custom-progress-bar bg-color-progress-bar-default bg-color-progress-bar-active">
          <div ref={progressBarDotRef} className="custom-progress-bar-dot bg-color-progress-bar-active" />
        </div>
      </div>
      <div className="col-2 center-progress-bar-items left-current-time font-color-progress-bar font-family-primary">
        <p className={days > 0 ? "current-days" : "current-time"}>{finalMeeting}</p>
      </div>
    </div>
  );
};

ProgressBar.propTypes = {
  currentMeetingTimes: PropTypes.object.isRequired,
  currentTime: PropTypes.instanceOf(Date).isRequired,
  isBusyCallback: PropTypes.func,
};

export default ProgressBar;

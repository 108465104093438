{
  "WelcomePage": {
    "Title": "Welcome",
    "Subtitle": "Seleziona il letto per vedere le Playlists dei video di riabilitazione associati",
    "LanguageSelection": "Premi il tasto INFO del telecomando per selezionare un'altra lingua"
  },
  "PlaylistPage": {
    "Title": "Playlists",
    "Subtitle": "Seleziona la playlist di tuo interesse"
  },
  "VideolistPage": {
    "Title": "Videos",
    "Subtitle": "Seleziona i video di tuo interesse"
  },
  "LastVideoPage": {
    "Title": "Ultima Playlist selezionata",
    "Subtitle": "Ultimo Video visualizzato: {{video}}",
    "Playlist": "Playlist: {{playlist}}",
    "GoToPlaylist": "Vai alle Playlist"
  }
}
